const vi = {
    Basic: "Cơ bản",
    Pro: "Chuyên nghiệp",
    month: "tháng",
    year: "năm",
    button: {
        getStarted: 'Bắt đầu',
        try: 'Bắt đầu thiết kế với AI',
        viewall: 'Xem tất cả',
        basic: 'Cơ bản',
        pro: 'Chuyên nghiệp',
        generate: 'Tạo thiết kế',
        backHome: 'Trở về trang chủ',
        googleSignin: 'Bắt đầu với Google',
        googleSignup: 'Bắt đầu với Google',
        emailSignin: 'Đăng nhập bằng Email',
        emailSignup: 'Bắt đầu với Email',
        submit: 'Gửi',
        choose: 'Chọn',
        download: 'Tải xuống mẫu này',
        upgrade: 'Mua thêm tín dụng',
        subscribe: 'Mua ngay',
        upgradePro: 'Nâng cấp',
        confirmPayment: 'Xác nhận thanh toán',
        tryThisFeature: 'Thử ngay',
        removeBG: 'Bắt đầu',
        professional: 'Bắt đầu',
        upscale: 'Bắt đầu',
        ok: 'Đồng ý',
        cancel: 'Hủy',
        select: 'Chọn',
        selectToStart: 'Chọn để bắt đầu',
        uploadPose: 'Tải lên tư thế',
        uploadingPose: 'Đang tải lên tư thế của bạn',
        upgradeToUpload: 'Nâng cấp để tải lên',
        unlock: "Nâng cấp",
        englishOnly: "Phiên bản tiếng Anh",
        contactUs: "Liên hệ",
        forEnterprise: "Cho doanh nghiệp",
        tryThis: "Thử",
        upscale: "Nâng cao",
        remove: "Xóa",
        render: "Tạo",
        rendering: "Đang tạo",
    },
    header: {
        nav: {
            signIn: 'Đăng nhập',
            signUp: 'Đăng ký',
            signOut: 'Đăng xuất',
            billing: 'Thanh toán',
            supportTitle: 'Hỗ trợ',
            supportContact: 'Liên hệ chúng tôi',
            myFlowers: 'Thiết kế của tôi'
        }
    },
    landing: {
        title: 'Thiết kế mọi nhà ở với AI',
        joinNow: 'Bạn đã sẵn sàng tạo ra những thiết kế tuyệt đẹp?',
        subTitle: "Ứng dụng sử dụng AI cho Trang trí ảo, Cải tạo, Thiết kế cảnh quan, Thiết kế nội thất và hơn thế nữa. Thiết kế ngay lập tức trong vài giây.",
        subTitle1: "Tiết kiệm thời gian và tiền bạc một cách dễ dàng. Được tin tưởng bởi chủ nhà, dịch vụ nhà ở Airbnb, công ty và chuyên gia trên toàn thế giới.",
        input: 'Hãy cho tôi biết loại hoa bạn muốn tạo?',
        inside: 'Trong nhà',
        outside: 'Ngoài trời',
        loveBy: '45,880+ thiết kế hài lòng đã được tạo',
        respectTitle: 'Chúng tôi tôn trọng quyền riêng tư của bạn',
        respectSub1: 'Không cần FaceID hoặc nhiều ảnh. Chỉ cần sử dụng một ảnh selfie, giống như bạn thường đăng trên Facebook hoặc Instagram.',
        respectSub2: 'Dữ liệu của bạn hoàn toàn an toàn và bảo mật trên nền tảng Google với độ bảo mật cao.',
        howItWorks: 'Cách hoạt động',
        howItWorksSub: 'Chụp hoặc tải lên -> Chọn phong cách và nền -> Chọn hoặc tải lên tư thế của bạn -> Nhận ảnh chân dung của bạn!',
        unBlurryTitle: 'Công cụ nâng cao chất lượng ảnh',
        unBlurrySub: 'Loại bỏ mờ, cải thiện và nâng cao màu sắc ảnh trước khi đăng lên các nền tảng mạng xã hội như Facebook, Instagram, Tiktok.',
        useThisFeature: 'Tạo ngay',
        anotherFeatureTitle: 'Tính năng nổi bật',
        anotherFeatureSub: 'Miễn phí, nhanh chóng và chính xác. Hãy thử các tính năng dưới đây.',
        feature1: 'Xóa nền',
        feature1Sub: 'Xóa chính xác mọi nền ảnh.',
        feature2: 'Thiết kế nội thất',
        feature2Sub: 'Thiết kế nội thất, ngoại thất và phác thảo với genhomeai.com',
        feature3: 'Thay đổi nền (Sắp ra mắt)',
        feature3Sub: 'Thay đổi nền cho bất kỳ đối tượng nào với AI của REOK',
        hotFeatureTitle: 'Khách hàng nói gì về chúng tôi',
        hotFeatureSub: 'Chân thực nhất. Được tin tưởng bởi các chuyên gia. Được tạo ra cho mọi người.',
        pricingTitle: 'Chọn gói của bạn',
        pricingLabel: 'Giá cả',
        pricingSub: '1 ảnh chuyên nghiệp = 10 tín dụng hoặc ít hơn',
        step1: 'Tải lên không gian của bạn',
        step1Sub: 'Chọn phong cách hoặc nhập phong cách của bạn.',
        step2: 'Nhà thiết kế AI sẽ làm việc trong vài giây',
        step2Sub: 'Nhanh chóng tạo ra các thiết kế trang trí cho bạn.',
        step3: 'Nhận thiết kế trang trí của bạn',
        step3Sub: 'Tải xuống thiết kế trang trí của bạn.',
        trialTitle: "Dùng thử MIỄN PHÍ",
        trialSubTitle: "#1 Công cụ tạo ảnh chân dung AI 📸",
        feedbackLabel: 'Phản hồi',
        faqLabel: 'Câu hỏi',
        sketch: 'Phác thảo',
        creativity: 'Sáng tạo',
        upscale4k: 'Nâng cấp',
        remove: 'Dọn phòng',
        fillup: 'Nội thất',
        exterior: 'Ngoại thất',
        interior: 'Nội thất',
        steps: 'Các bước',
        interiorShowCase: {
            label: "NỘI THẤT",
            title: "Thiết kế lại với bất kỳ phong cách nào",
            subTitle: "Chúng tôi giúp bạn thay đổi không gian của mình với các phong cách khác nhau, tạo nên vẻ đẹp tuyệt vời. Thiết kế lại mọi loại nội thất, ngoại thất hoặc vườn."
        },
        exteriorShowCase: {
            label: "NGOẠI THẤT",
            title: "Thiết kế lại sáng tạo cho ngoại thất",
            subTitle: "Cải tạo không gian trong nhà và ngoài trời của bạn với các giải pháp thiết kế lại sáng tạo, thổi hơi thở mới vào mọi góc cạnh để tạo nên một bầu không khí thực sự cuốn hút."
        },
        furnitureShowCase: {
            label: "NỘI THẤT",
            title: "Lấp đầy phòng trống bằng nội thất",
            subTitle: "Bạn có thể thử nhiều cách bố trí khác nhau cho đến khi tìm được cách hoàn hảo. Trang trí phòng trống bằng nội thất với Decorify. Hoạt động tốt cho các không gian đang xây dựng hoặc trống rỗng."
        },
        cleanRoomShowCase: {
            label: "DỌN PHÒNG",
            title: "Loại bỏ đồ vật không mong muốn",
            subTitle: "Dễ dàng biến đổi phòng của bạn bằng cách loại bỏ bất kỳ đồ vật không mong muốn nào, cho phép bạn tạo ra môi trường hoàn hảo phù hợp với sở thích của mình."
        },
        upscaleShowCase: {
            label: "NÂNG CẤP",
            title: "Nâng cấp bất kỳ thiết kế nào lên 4K",
            subTitle: "Nâng cao không khí của bất kỳ môi trường nào với dịch vụ thiết kế nâng cấp của chúng tôi, đảm bảo độ rõ nét và chi tiết tuyệt vời lên đến độ phân giải 4K để tạo ra trải nghiệm thực sự sống động."
        },
        creativityShowCase: {
            label: "SÁNG TẠO",
            title: "Sáng tạo AI tùy chỉnh",
            subTitle: "Kiểm soát hành trình thiết kế của bạn với sự can thiệp của AI. Bạn quyết định mức độ hỗ trợ của AI. Cho dù bạn chỉ muốn một vài gợi ý hay một sự thay đổi hoàn toàn, tất cả đều tùy thuộc vào bạn."
        },
        sketchShowCase: {
            label: "PHÁC THẢO",
            title: "Từ phác thảo đến thiết kế thực tế",
            subTitle: "Biến đổi bất kỳ bản phác thảo nào thành thiết kế thực tế sống động, mang ý tưởng của bạn đến hiện thực với độ chính xác và tính xác thực cao."
        },
    },
    yearbook: {
        title: 'AI Yearbook photoshoot 📸',
        subTitle: "Not using many Selfie images. Get your 90s Yearbook photos with a single Selfie with our AI-photographer in seconds. Save 10x your time.",
        input: 'Tell me the type of flower you want to create?',
        inside: 'Indoor',
        outside: 'Outdoor',
        loveBy: '45,880+ happy designs already created',
        respectTitle: 'We respect your privacy',
        respectSub1: 'We do not use FaceID or require a lot of photos to perform. Just use a Selfie, similar to what you often post on Facebook and Instagram.',
        respectSub2: 'Your data is completely safe and secure on Google platform very high security.',
        howItWorks: 'How AI Yearbook Works',
        howItWorksSub: 'You take a selfie or use a clear ID photo, select your style & pose do headshots in seconds not hours!',
        unBlurryTitle: 'Photo upscaler',
        unBlurrySub: 'Remove blurs, enhance, and improve photo colors before posting on social media platforms like Facebook, Instagram, Tiktok.',
        useThisFeature: 'Create yours now',
        anotherFeatureTitle: 'Outstanding Features',
        anotherFeatureSub: 'Free, fast, and accurate. Try these features below.',
        feature1: 'Remove backgrounds',
        feature1Sub: 'Accurately remove any background.',
        feature2: 'Interior Design',
        feature2Sub: 'Interior design, exterior design, and sketching with genhomeai.com',
        feature3: 'Change Backgrounds (Coming soon)',
        feature3Sub: 'Change backgrounds for any object with REOK’s AI',
        hotFeatureTitle: 'Our AI Yearbook Photos',
        hotFeatureSub: 'These are AI-generated headshots.',
        pricingTitle: 'Pick your plan',
        pricingLabel: 'Pricing',
        pricingSub: '1 professional photo = 10 credits or less',
    },
    showcase: {
        title: 'Mẫu thiết kế',
        status: {
            pending: 'đang xử lý',
            upscaling: 'đang nâng cấp',
            choosing: 'đang chọn',
            finished: 'hoàn thành'
        },
        mode: {
            pro: 'Chuyên nghiệp',
            basic: 'Cơ bản'
        }
    },
    home: {
        title: 'Thiết kế của bạn',
        hotColletion: "Phong cách tùy chỉnh",
        credits: 'Số thiết kế còn lại',
        uploadTitle: 'Tải lên không gian của bạn',
        uploadPlaceHolder: 'Kéo thả file hoặc nhấp để tải lên',
        stylePlaceHolder: 'Chọn',
        uploadPosePlaceHolder: 'Chọn',
        uploadBGPlaceHolder: 'Chọn',
        roomTitle: 'Chọn loại phòng (29)',
        themeTitle: 'Chọn phong cách phòng (34)',
        pro: {
            title: 'Sáng tạo cho mọi loại thiết kế',
            input: 'Mô tả thiết kế của bạn...',
        },
        basic: {
            title: 'Sáng tạo không giới hạn với Pro',
            chooseFlowerTitle: 'Thiết kế',
            flowers: 'Phòng khách',
            styles: 'Phong cách',
            decoration: 'Trang trí',
            events: 'Sự kiện',
            backgrounds: 'Nền'
        },
    },
    profile: {
        title: 'Thiết kế của tôi',
        before: 'Trước -',
        after: 'Sau -'
    },
    terms: {
        title: 'Điều khoản và Điều kiện',
        content: ''
    },
    //Part 2
    privacy: {
        title: 'Chính sách bảo mật',
        one: 'Quyền riêng tư của bạn rất quan trọng đối với chúng tôi. Chính sách bảo mật của decorify.pro tôn trọng quyền riêng tư của bạn đối với bất kỳ thông tin nào chúng tôi có thể thu thập từ bạn trên trang web của chúng tôi, decorify.pro, và các trang web khác mà chúng tôi sở hữu và vận hành.',
        two: 'Chúng tôi chỉ yêu cầu thông tin cá nhân khi chúng tôi thực sự cần nó để cung cấp dịch vụ cho bạn. Chúng tôi thu thập nó bằng các phương tiện công bằng và hợp pháp, với sự hiểu biết và đồng ý của bạn. Chúng tôi cũng cho bạn biết lý do tại sao chúng tôi thu thập nó và cách nó sẽ được sử dụng.',
        three: 'Chúng tôi chỉ lưu giữ thông tin đã thu thập trong thời gian cần thiết để cung cấp cho bạn dịch vụ bạn yêu cầu. Những dữ liệu chúng tôi lưu trữ, chúng tôi sẽ bảo vệ trong phạm vi chấp nhận được về mặt thương mại để ngăn chặn mất mát và trộm cắp, cũng như truy cập, tiết lộ, sao chép, sử dụng hoặc sửa đổi trái phép.',
        four: 'Chúng tôi không chia sẻ bất kỳ thông tin nhận dạng cá nhân nào công khai hoặc với bên thứ ba, trừ khi luật pháp yêu cầu.',
        five: 'Trang web của chúng tôi có thể liên kết đến các trang web bên ngoài không do chúng tôi vận hành. Xin lưu ý rằng chúng tôi không kiểm soát nội dung và thực tiễn của các trang web này và không thể chịu trách nhiệm hoặc nghĩa vụ đối với các chính sách bảo mật tương ứng của họ.',
        six: 'Bạn có quyền từ chối yêu cầu thông tin cá nhân của chúng tôi, với sự hiểu biết rằng chúng tôi có thể không thể cung cấp cho bạn một số dịch vụ mong muốn.',
        seven: 'Việc bạn tiếp tục sử dụng trang web của chúng tôi sẽ được coi là chấp nhận các thực tiễn của chúng tôi xung quanh quyền riêng tư và thông tin cá nhân. Nếu bạn có bất kỳ câu hỏi nào về cách chúng tôi xử lý dữ liệu người dùng và thông tin cá nhân, vui lòng liên hệ với chúng tôi.',
        eight: 'Chính sách này có hiệu lực từ ngày 15 tháng 9 năm 2023.'
    },
    refund: {
        moneyBack: 'Đảm bảo hoàn tiền',
        title: 'Chính sách hoàn tiền',
        one: 'Mục tiêu của chúng tôi là cung cấp dịch vụ và kết quả chất lượng cao cho tất cả khách hàng. Chúng tôi hiểu rằng có thể có những trường hợp kết quả không đáp ứng được mong đợi của bạn',
        two: 'Chúng tôi đảm bảo sự hài lòng của bạn. Nếu bạn không hài lòng với các bức ảnh được tạo ra bằng Reok.pro, chúng tôi rất sẵn lòng hoàn lại toàn bộ số tiền cho bạn. Hoặc trong một số trường hợp, chúng tôi có thể đề nghị làm lại ảnh của bạn để đáp ứng tốt hơn mong đợi của bạn.',
        three: 'Chúng tôi sẽ xem xét và phản hồi các yêu cầu hoàn tiền, dựa trên chính sách này, trong vòng 1-3 ngày làm việc. Nếu yêu cầu hoàn tiền của bạn được chấp thuận, tiền sẽ được hoàn lại vào thẻ của bạn trong vòng 5-10 ngày làm việc. Việc hoàn tiền sẽ được xử lý trở lại phương thức thanh toán ban đầu được sử dụng trong quá trình mua hàng; chúng tôi không thể hoàn tiền vào một tài khoản khác.',
        four: 'Chúng tôi chỉ có thể xử lý các yêu cầu hoàn tiền được gửi trong vòng 30 ngày kể từ ngày mua và sử dụng DƯỚI 20 tín dụng.',
        five: 'Để yêu cầu hoàn tiền, vui lòng gửi email cho chúng tôi tại hello@decorify.pro hoặc sử dụng tính năng trò chuyện có sẵn trên trang web của chúng tôi. Khi liên hệ với chúng tôi về việc hoàn tiền, vui lòng cung cấp địa chỉ email bạn đã sử dụng khi đặt hàng.',
        six: 'Cảm ơn bạn đã chọn Reok.pro cho ảnh chân dung của bạn.',
    },
    footer: {
        title: 'Decorify là một ứng dụng sử dụng AI cho Trang trí ảo, Cải tạo, Thiết kế cảnh quan, Thiết kế nội thất và hơn thế nữa.',
        terms: 'Điều khoản',
        privacy: 'Chính sách bảo mật',
        app: 'Ứng dụng',
        signin: 'Đăng nhập',
        signup: 'Đăng ký',
        email: 'Email',
        chatNow: 'Trò chuyện ngay',
        partner: 'Đối tác',
        reportBug: 'Báo cáo lỗi',
        require: 'Gửi yêu cầu',
        page: 'Trang',
        tos: 'Pháp lý',
        contact: 'Liên hệ',
        other: 'Khác',
        affiliate: 'Liên kết',
        yearbook: 'Kỷ yếu AI',
        blog: 'Blog',
        pricing: 'Giá cả',
        howItWorks: 'Cách hoạt động',
        sendFeedback: 'Gửi phản hồi'
    },
    tos: {
        agree: 'Tài khoản mới phải tuân theo ',
        one: '1. Giới thiệu',
        oneDetail: 'Bằng cách sử dụng decorify.pro, bạn đồng ý và bị ràng buộc bởi các điều khoản và điều kiện này.',
        two: '2. Đồng ý với Điều khoản',
        twoDetail: 'Thỏa thuận này có hiệu lực từ ngày bạn sử dụng ứng dụng decorify.pro lần đầu tiên.',
        three: '3. Tính năng',
        threeDetail: 'Bạn sẽ có thể sử dụng tất cả các tính năng sau khi đăng ký một gói trả phí cụ thể. Chi tiết về các tính năng có sẵn có thể được tìm thấy trên trang giá.',
        four: '4. Chính sách hoàn tiền',
        fourDetail: 'Chúng tôi cho phép hoàn tiền cho các giao dịch thất bại trong vòng 14 ngày kể từ ngày mua. Liên hệ với bộ phận hỗ trợ để yêu cầu hoàn tiền.',
        five: '5. Sử dụng sản phẩm',
        fiveDetail: 'Bằng cách sử dụng decorify.pro, bạn đồng ý nhận các cập nhật quan trọng về sản phẩm từ decorify.pro qua email liên kết với tài khoản Google của bạn hoặc email bạn đã sử dụng để đăng ký tài khoản. Bạn có thể từ chối nhận các cập nhật sản phẩm này bất cứ lúc nào bằng cách nhấp vào liên kết "Hủy đăng ký" ở cuối mỗi email. Chúng tôi chỉ gửi các cập nhật sản phẩm quan trọng.',
        six: '6. Tuyên bố từ chối trách nhiệm',
        sixDetail: 'Chúng tôi không đảm bảo rằng decorify.pro sẽ đáp ứng các yêu cầu của bạn hoặc hoạt động của nó sẽ không bị gián đoạn hoặc không có lỗi. Tất cả các bảo đảm hoặc điều kiện không được nêu rõ trong Thỏa thuận này (bao gồm, nhưng không giới hạn, mất lợi nhuận, mất mát hoặc hỏng dữ liệu, gián đoạn kinh doanh, hoặc bất kỳ tổn thất, thiệt hại, chi phí hoặc phí tổn nào khác dưới bất kỳ hình thức nào) đều được loại trừ và từ chối trong phạm vi tối đa được pháp luật cho phép. Thỏa thuận này không ảnh hưởng đến bất kỳ quyền theo luật định nào mà bạn có thể có với tư cách là người tiêu dùng.',
        seven: '7. Giới hạn bảo hành và pháp lý',
        sevenDetail: 'decorify.pro không đưa ra bất kỳ bảo đảm, đảm bảo hoặc đại diện nào khác về chất lượng, sự phù hợp cho một mục đích cụ thể, hoặc các đặc điểm hoặc hiệu suất khác của phần mềm. decorify.pro sẽ không chịu trách nhiệm với bạn về bất kỳ lợi nhuận bị mất hoặc bất kỳ thiệt hại hậu quả, đặc biệt, ngẫu nhiên hoặc gián tiếp nào phát sinh từ hoặc liên quan đến Thỏa thuận này hoặc việc bạn sử dụng decorify.pro (dù phát sinh như thế nào, bao gồm cả sự bất cẩn) trừ trường hợp trách nhiệm không thể bị loại trừ theo luật.',
        eight: '8. Điều khoản chung và Luật pháp',
        eightDetail: 'Bạn thừa nhận rằng không có mối quan hệ liên doanh, hợp tác, việc làm hoặc đại lý nào tồn tại giữa bạn và decorify.pro do việc sử dụng các dịch vụ này. Bạn đồng ý không tự xưng là đại diện, đại lý hoặc nhân viên của decorify.pro. Bạn đồng ý rằng decorify.pro sẽ không chịu trách nhiệm với bạn về bất kỳ hành động nào mà chúng tôi thực hiện để ngăn chặn hoặc giải quyết việc sử dụng sai mục đích nền tảng của chúng tôi. Bạn thừa nhận rằng, trong việc cung cấp cho bạn việc sử dụng decorify.pro, chúng tôi đã dựa vào sự đồng ý của bạn để bị ràng buộc bởi các điều khoản của Thỏa thuận này.',
    },
    faq: {
        title: 'Câu hỏi thường gặp',
        q1: 'Decorify hoạt động như thế nào?',
        answer1: 'Decorify là một ứng dụng web cho phép bạn biến đổi và thiết kế lại tất cả các phần trong ngôi nhà của bạn, bao gồm nội thất, ngoại thất, vườn, sân hiên và hơn thế nữa. Sử dụng sức mạnh của Trí tuệ Nhân tạo, ứng dụng tạo ra các ý tưởng mới mỗi vài giây, cung cấp nhiều phong cách, chế độ, loại phòng và nhiều tùy chọn khác để giúp bạn đạt được kết quả mong muốn.',
        q2: 'Chắc chắn nó tiết kiệm tiền và thời gian của tôi?',
        answer2: 'Đúng vậy, ứng dụng của chúng tôi cung cấp giải pháp tiết kiệm chi phí cho cả sử dụng cá nhân và chuyên nghiệp. Không cần thuê các chuyên gia đắt tiền, bạn có thể hình dung ngôi nhà của mình sẽ trông như thế nào với các đồ nội thất, trang trí và thiết kế sân vườn khác nhau, mà không cần phải lướt qua vô số trang web để tìm ý tưởng. Các nhà thiết kế AI của chúng tôi phân tích sở thích của bạn và tạo ra các đề xuất cá nhân hóa, giúp bạn dễ dàng hình dung và tạo ra những ngôi nhà tuyệt đẹp.',
        q3: 'Nó có hoạt động trên mọi thiết bị không? (Laptop, MacOS, PC, iOS, Android)',
        answer3: 'Tất nhiên! Bạn không cần cài đặt bất kỳ ứng dụng di động hoặc chương trình máy tính nào, bạn chỉ cần chạy nó từ các trình duyệt phổ biến nhất (Chrome, Firefox, Safari).',
        q4: 'Tôi có thể sử dụng nó dễ dàng như thế nào?',
        answer4: 'Chỉ cần tải lên hình ảnh của bạn và bắt đầu tạo ra các ý tưởng AI mới.',
        q5: 'Ai có thể sử dụng Decorify AI?',
        answer5: 'Ứng dụng của chúng tôi dễ sử dụng cho bất kỳ ai, bất kể kiến thức hay chuyên môn của họ về nội thất, vườn hoặc kiến trúc. Nó có thể được sử dụng bởi chủ nhà muốn cải tạo hoặc xây dựng, kiến trúc sư cần ý tưởng mới hoặc kết quả ngay lập tức, nhà thiết kế nội thất tìm kiếm gợi ý trang trí và thậm chí cả nhà thiết kế cảnh quan. Các đại lý và công ty bất động sản cũng sử dụng phần mềm của chúng tôi để nâng cao danh sách của họ, bán nhà dễ dàng hơn và hiển thị nhanh các ý tưởng nội thất và ngoại thất AI.',
        q6: 'Làm thế nào các nhà thiết kế AI có thể nâng cao ngôi nhà của tôi?',
        answer6: 'Các nhà thiết kế AI cung cấp vô số khả năng cho nội thất của bạn. Bạn có thể khám phá nhiều phong cách và tùy chọn trang trí để tạo ra một không gian sống hài hòa và hấp dẫn về mặt thị giác.',
        q7: 'Nó có hoạt động với các bản vẽ kiến trúc và phác thảo không?',
        answer7: 'Tất nhiên! Phần mềm của chúng tôi được thiết kế để làm việc với các bản phác thảo kiến trúc. Cho dù bạn có bản phác thảo vẽ tay hay bản vẽ kỹ thuật số, bạn có thể dễ dàng tải chúng lên nền tảng của chúng tôi và sau đó chuyển đổi bản phác thảo của bạn thành hình ảnh thực tế.',
        q8: 'Nó có hoạt động cho các ngôi nhà/nội thất/ngoại thất đang xây dựng không?',
        answer8: 'Tất nhiên! Người dùng của chúng tôi đang sử dụng phần mềm này rất nhiều để tạo ra ý tưởng về cách trang trí nội thất và ngoại thất đang xây dựng của họ (phòng, nhà, sân sau).',
        q9: 'Nó có hoạt động với việc dựng cảnh ảo và dựng cảnh nhà không?',
        answer9: 'Tất nhiên! Bằng cách tải lên ảnh của một phòng trống hoặc một không gian hiện có, chúng tôi giúp bạn thử nghiệm với việc dựng cảnh ảo. Đây là một công cụ rất có giá trị cho cả các chuyên gia bất động sản, chủ nhà và dịch vụ nhà ở Airbnb.',
        q10: 'Bạn có chính sách hoàn tiền không?',
        answer10: 'Có! Vui lòng xem chính sách hoàn tiền của chúng tôi để biết chi tiết.'
    },
    signin: {
        title: 'Đăng nhập',
        another: 'Hoặc bằng Email',
        emailPlaceHolder: 'Địa chỉ Email',
        passwordPlaceHolder: 'Mật khẩu',
        rememberTitle: 'Ghi nhớ cho lần sau',
        forgetPassword: 'Quên mật khẩu?',
        noAccount: 'Chưa có tài khoản?'
    },
    signup: {
        title: 'Tạo tài khoản mới hoặc đăng nhập với tài khoản hiện có.',
        another: 'Hoặc bằng Email',
        firstNamePlaceHolder: 'Tên',
        lastNamePlaceHolder: 'Họ',
        emailPlaceHolder: 'Địa chỉ Email',
        passwordPlaceHolder: 'Mật khẩu',
        rememberTitle: 'Ghi nhớ cho lần sau',
        forgetPassword: 'Quên mật khẩu?',
        gotAccount: 'Đã có tài khoản?'
    },
    // Part 3
    forgetPassword: {
        title: 'Khôi phục mật khẩu',
        content: 'Chúng tôi sẽ gửi email khôi phục mật khẩu cho bạn',
        emailPlaceHolder: 'Vui lòng nhập Email của bạn',
        sendBtn: 'Gửi',
        backSignin: 'Quay lại đăng nhập'
    },
    pricing: {
        yearlySubscription: '1000 thiết kế',
        monthlySubscription: '100 thiết kế',
        saveDesk: 'Tiết kiệm 10%',
        saveMobile: 'Tiết kiệm 10% khi mua 1000 thiết kế',
        popular: "76% chọn gói này",
        free: {
            title: 'Tín dụng miễn phí',
            price: '0đ',
            trialTitle: 'Mã đăng ký: Vui lòng đăng nhập',
            joinTitle: 'Dán mã của bạn vào đây',
            one: 'Nhận 20 tín dụng miễn phí',
            two: 'Bao gồm tất cả tính năng của 100 tín dụng',
            affiliateTitle: 'Chương trình liên kết',
            shareTitle: 'Chia sẻ với bạn bè👇',
            loginToShare: 'Vui lòng đăng nhập để chia sẻ'
        },
        basic: {
            title: '100 tín dụng',
            price: '7đ',
            duration: '/trọn đời',
            recommend: 'Cho cá nhân',
            one: '20 ảnh chuyên nghiệp',
            two: 'Xóa nền',
            three: 'Nâng cấp lên 4K',
            four: 'Tải xuống tất cả ảnh',
            five: 'Lưu trữ đám mây không giới hạn',
            six: 'Sử dụng ảnh cho mục đích thương mại',
            seven: 'Truy cập tất cả tính năng mới',
            subscribed: 'Đã mua'
        },
        pro: {
            title: '1000 tín dụng',
            subTitle: '(Tiết kiệm 30%)',
            price: '49đ',
            duration: '/trọn đời',
            recommend: 'Chủ quán cà phê, doanh nghiệp, studio ảnh',
            one: '200 ảnh chuyên nghiệp',
            two: 'Bao gồm tất cả tính năng của 100 tín dụng',
            three: 'Tiết kiệm tiền',
            subscribed: 'Đã mua'
        },
        credit: {
            title: 'Chọn gói phù hợp với nhu cầu của bạn!'
        },
        first: {
            title: 'KHỞI ĐẦU',
            price: '$19',
            priceDetail: '100 thiết kế - không đăng ký',
            duration: '/trọn đời',
            recommend: '1 Ảnh chân dung = 5 tín dụng',
            five: 'Trang trí với nhà thiết kế AI cơ bản',
            credit: '500 tín dụng',
            two: '100 thiết kế/lần mua',
            three: 'Mở khóa tất cả chế độ',
            one: 'Xóa đồ nội thất bằng AI',
            six: 'Giấy phép thương mại',
            four: 'Phác thảo thành hình ảnh',
            seven: '85+ loại phòng có sẵn',
            eight: '40+ phong cách có sẵn',
            nine: 'Nâng cấp bất kỳ thiết kế nào lên 4K',
            ten: 'Tải xuống chất lượng cao',
            eleven: 'Lưu trữ không giới hạn',
            twelve: 'Truy cập sớm các tính năng mới',
            thirteen: 'Hủy bất cứ lúc nào',
            fourteen: 'Dựng cảnh ảo AI',
        },
        second: {
            title: 'PRO',
            subTitle: '(Tiết kiệm 85%)',
            price: '$29',
            priceDetail: '1,000 thiết kế mỗi tháng',
            duration: '/trọn đời',
            recommend: 'Chủ quán cà phê, doanh nghiệp, studio ảnh',
            five: 'Luôn có nhà thiết kế AI mới nhất',
            credit: '5,000 tín dụng',
            two: '1,000 thiết kế/tháng',
            three: 'Mở khóa tất cả chế độ',
            one: 'Xóa đồ nội thất bằng AI',
            six: 'Giấy phép thương mại',
            four: 'Phác thảo thành hình ảnh',
            seven: '85+ loại phòng có sẵn',
            eight: '40+ phong cách có sẵn',
            nine: 'Nâng cấp bất kỳ thiết kế nào lên 4K',
            ten: 'Tải xuống chất lượng cao',
            eleven: 'Lưu trữ không giới hạn',
            twelve: 'Truy cập sớm các tính năng mới',
            thirteen: 'Hủy bất cứ lúc nào',
            fourteen: 'Dựng cảnh ảo AI',
            subscribed: 'Đăng ký'
        },
        third: {
            title: 'PRO HÀNG NĂM',
            subTitle: '(Tiết kiệm 93%)',
            price: '$199',
            priceDetail: '12,000 thiết kế mỗi năm',
            duration: '/trọn đời',
            recommend: 'Chủ quán cà phê, doanh nghiệp, studio ảnh',
            five: 'Luôn có nhà thiết kế AI mới nhất',
            credit: '60,000 tín dụng',
            two: '12,000 thiết kế/năm',
            three: 'Mở khóa tất cả chế độ',
            one: 'Xóa đồ nội thất bằng AI',
            six: 'Giấy phép thương mại',
            four: 'Phác thảo thành hình ảnh',
            seven: '85+ loại phòng có sẵn',
            eight: '40+ phong cách có sẵn',
            nine: 'Nâng cấp bất kỳ thiết kế nào lên 4K',
            ten: 'Tải xuống chất lượng cao',
            eleven: 'Lưu trữ không giới hạn',
            twelve: 'Truy cập sớm các tính năng mới',
            thirteen: 'Hủy bất cứ lúc nào',
            fourteen: 'Dựng cảnh ảo AI',
            subscribed: 'Đăng ký'
        },
        four: {
            title: 'Giải pháp doanh nghiệp',
            subTitle: '(Liên hệ chúng tôi)',
            price: '0đ',
            priceDetail: '0đ / ảnh chân dung',
            duration: '/trọn đời',
            recommend: 'Chủ quán cà phê, doanh nghiệp, studio ảnh',
            one: 'Ảnh chân dung chất lượng cao',
            two: 'Công ty hoặc nhóm làm việc từ xa',
            three: 'Tiết kiệm thời gian và tiền bạc',
            four: 'Chụp ảnh chân dung trong vài giây',
            five: 'Không cần chụp ảnh truyền thống',
            subscribed: 'Đã mua',
        },
        currency: 'đ',
        perMonth: '/tháng',
        safePayment: 'Thanh toán an toàn',
        qr: 'Hoặc sử dụng mã QR'
    },
    milestoneMessage: {
        first: 'Khách hàng hài lòng hơn với',
        second: 'thiết kế đã tạo'
    },
    message: {
        success: {
            redering: 'Thiết kế của bạn đang được tạo, vui lòng đợi trong giây lát',
            rederingDone: 'AI đã hoàn thành việc tạo thiết kế',
            activated: 'Kích hoạt thành công! Hãy tận hưởng việc tạo ra những thiết kế đẹp và thỏa mãn!',
            removingBG: 'Đang xóa nền của bạn',
            upscale: 'Đang nâng cao độ phân giải',
            resetPassword: 'Chúng tôi vừa gửi email để đặt lại mật khẩu của bạn. Vui lòng kiểm tra!',
            signIn: 'Chúng tôi vừa gửi email để kích hoạt tài khoản của bạn. Vui lòng kiểm tra!',
            reviewing: 'Chúng tôi đã nhận được yêu cầu của bạn. Nó sẽ được xem xét trong vòng một ngày',
            remindReviewing: 'Ảnh này đang được xem xét để nhận tín dụng mới',
            removed: 'Đã xóa ảnh thành công',
            useTheTemplate: "Đang sử dụng mẫu",
            subscribed: "Bạn đã đăng ký thành công!",
        },
        error: {
            selectPhotographer: 'Vui lòng chọn nhà thiết kế AI của bạn để bắt đầu',
            uploadPhoto: 'Vui lòng tải lên không gian của bạn',
            chooseRoom: 'Vui lòng chọn loại phòng',
            chooseTheme: 'Vui lòng chọn phong cách phòng',
            fileOverBasic: 'Vui lòng tải lên file nhỏ hơn 5MB, hoặc nâng cấp lên Pro để tải lên file lớn hơn',
            fileOverPro: 'Vui lòng tải lên file nhỏ hơn 10MB',
            wrongFile: 'File không hợp lệ, vui lòng thử file khác',
            wrongFace: 'Có chút gián đoạn, bạn hãy thử lại!',
            busy: 'Kết nối bị gián đoạn, vui lòng thử lại',
            gender: 'Vui lòng chọn giới tính của bạn',
            age: 'Vui lòng chọn độ tuổi của bạn',
            roomType: 'Vui lòng chọn loại',
            style: 'Vui lòng chọn một phong cách hoặc tùy chỉnh của bạn',
            numberOfResult: 'Vui lòng chọn số lượng ảnh chân dung',
            outOfCredits: 'Bạn đã hết tín dụng, vui lòng mua thêm tín dụng!',
            userNotFound: 'Không tìm thấy tài khoản này. Vui lòng nhập chính xác tài khoản của bạn!',
            wrongPassword: 'Sai mật khẩu! Nếu bạn không nhớ mật khẩu, vui lòng sử dụng chức năng Quên mật khẩu.',
            accountExist: 'Tài khoản đã tồn tại. Hãy thử đăng nhập bằng tài khoản này',
            tiktok: 'Vì lý do bảo mật của Google User, vui lòng sử dụng trình duyệt Chrome hoặc Safari. Truy cập decorify.pro để sử dụng.',
            reviewing: 'Bạn không thể xóa ảnh này vì nó đang được xem xét',
            background: 'Vui lòng chọn nền của bạn',
            pose: 'Vui lòng chọn tư thế của bạn',
            signUpReCaptcha: 'Vui lòng hoàn thành reCAPTCHA trước khi đăng ký.',
            emptyBG: 'Nền của bạn đang trống, vui lòng nhập vào!',
            tooShortBG: 'Nền của bạn nên dài hơn 2 từ.',
            tooLongBG: 'Nền của bạn quá dài.',
            badWordsBG: 'Nền của bạn chứa từ không phù hợp. Vui lòng thử lại',
            emptyStyle: 'Phong cách của bạn đang trống, vui lòng nhập vào!',
            tooShortStyle: 'Phong cách của bạn nên dài hơn 2 từ.',
            tooLongStyle: 'Phong cách của bạn quá dài.',
            badWordsStyle: 'Phong cách của bạn chứa từ không phù hợp. Vui lòng thử lại',
            emptyEmail: 'Vui lòng nhập email của bạn',
            validEmail: 'Vui lòng nhập một địa chỉ email hợp lệ',
            subscribeError: 'Xin lỗi, chúng tôi không thể đăng ký cho bạn.'
        },
        warning: {
            runOutBasicCredits: 'Bạn đã sử dụng hết tín dụng trong gói Basic, vui lòng mua thêm tín dụng',
            runOutProCredits: 'Vui lòng mua thêm tín dụng để sử dụng ^^',
            selectPose: 'Vui lòng chọn Giới tính của bạn trước khi chọn Tư thế',
            aiVirtualStaging: 'Chuyển sang Dựng cảnh ảo AI',
            aiFurnitureRemoval: 'Chuyển sang Xóa đồ nội thất bằng AI. Tải xuống hình ảnh trước khi rời đi, vì lịch sử xóa không được lưu.',
            upscale: 'Chuyển sang Nâng cao độ phân giải',
        },
        prompt: {
            professional: 'Ảnh chuyên nghiệp',
            removeBG: 'Xóa nền',
            upscale: 'Nâng cao độ phân giải',
            rendering: 'AI đang tạo',
            faceNotFound: 'Khuôn mặt của bạn quá gần, vui lòng thử ảnh selfie khác!',
            inReview: 'Đang xem xét',
            styles: {
                business: 'Ảnh doanh nghiệp chuyên nghiệp',
                realEstate: 'Ảnh môi giới bất động sản chuyên nghiệp',
                carShowroom: 'Ảnh nhân viên bán xe chuyên nghiệp',
                doctor: 'Ảnh bác sĩ chuyên nghiệp',
                yearbook: 'Ảnh kỷ yếu thập niên 90 chuyên nghiệp',
                wedding: 'Ảnh cưới chuyên nghiệp',
                halloween: 'Ảnh Halloween'
            },
        },
        maintain: {
            backsoon: 'Chúng tôi đang bảo trì tính năng này và sẽ sớm hoạt động trở lại. Vui lòng sử dụng các tính năng khác trong thời gian chờ đợi. Cảm ơn bạn! 💗💗💗'
        }
    },    
    // Part 4 
    app : {
        menu: {
            creditTitle: 'Tín dụng của bạn',
            affiliateTitle: 'Chương trình liên kết',
            affiliateSub: 'Nhận hoa hồng 30% trên các khoản thanh toán của tất cả khách hàng bạn giới thiệu cho chúng tôi!',
            professionalMode: 'Thiết kế nhà AI',
            removeBGMode: 'Xóa đồ nội thất',
            unBluryMode: 'Nâng cao thiết kế',
            myPhotos: 'Thiết kế của tôi',
            aiVirtualStaging: "Dựng cảnh ảo AI"
        },
        tryThese: 'Không có hình ảnh? Hãy thử những cái này:',
        goodSelfie: 'Ảnh selfie tốt',
        badSelfie: 'Ảnh selfie xấu',
        trick: 'Sử dụng Nâng cao để có chất lượng tốt hơn',
        removeBGSub: 'Xóa nền trở nên nhanh chóng, đơn giản và chính xác với AI.',
        professionalMode: {
            title: 'Trang trí bất kỳ không gian nào với AI',
            refundTitle: 'AI của chúng tôi đã học hỏi để có kết quả tuyệt vời 💓',
            creditTitle: '1 ảnh = 10 tín dụng',
            genderTitle: 'Chọn loại',
            ageTitle: 'Chọn độ tuổi của bạn',
            styleTitle: 'Chọn phong cách hoặc tùy chỉnh',
            quantityTitle: 'Số lượng thiết kế',
            poseTitle: 'Chọn/tải lên tư thế',
            backgroundTitle: 'Chọn/tùy chỉnh nền',
            skipPose: 'Bỏ qua để giữ tư thế gốc của bạn',
            defaultPose: 'Mặc định',
            uploadedPose: 'Đã tải lên',
            defaultBackground: 'Nền',
            defaultStyle: 'Phong cách',
            headshotsTab: 'Ảnh chân dung',
            selectPhotographer: 'Chọn nhà thiết kế AI của bạn',
            selectPhotographerSub: 'Vui lòng chọn nhà thiết kế phù hợp với nhu cầu của bạn',
            requirements: 'Tùy chỉnh',
            guideSub: 'Làm theo yêu cầu của nhà thiết kế 👇',
            creativity: "Sáng tạo AI",
            customize: "✍️ tùy chỉnh (beta)",
            enOnlyCustomize: "✍️ tùy chỉnh (chỉ phiên bản tiếng Anh)",
            interiorsTitle: "Nội thất",
            exteriorsTitle: "Ngoại thất",
            gardensTitle: "Vườn",
        },
        upscaleMode: {
            title: 'Nhanh chóng và chính xác loại bỏ mờ và nâng cao ảnh của bạn với AI.',
        }
    },
    myDesign: {
        title: 'Thiết kế của tôi',
        scrollTitle: 'Cuộn để xem tất cả thiết kế của bạn.',
    },
    imageComponent: {
        compare: 'Xem',
        download: 'Tải xuống',
        removeObjects: 'Xóa đồ nội thất',
        upscale: 'Nâng cao',
        virtualHome: 'Dựng cảnh trang trí',
        errorPhoto: 'Làm mới ảnh xấu'
    },
    results: {
        one: '1 thiết kế',
        four: '4 thiết kế',
    },
    genders: {
        man: 'Nam',
        woman: 'Nữ'
    },
    styles: {
        "modern": "Hiện đại",
        "minimalist": "Tối giản",
        "professional": "Chuyên nghiệp",
        "tropical": "Nhiệt đới",
        "coastal": "Ven biển",
        "vintage": "Cổ điển",
        "neoclassic": "Tân cổ điển",
        "tribal": "Bộ lạc",
        "industrial": "Công nghiệp",
        "bohemian": "Bohemian",
        "contemporary": "Đương đại",
        "farmhouse": "Nhà trang trại",
        "scandinavian": "Bắc Âu",
        "skichalet": "Nhà gỗ trượt tuyết",
        "artdeco": "Nghệ thuật trang trí",
        "biophilic": "Thân thiện với thiên nhiên",
        "christmas": "Giáng sinh",
        "tetvn": "Tết Nguyên đán",
        "midautumn": "Trung thu",
        "halloween": "Halloween",
        "easter": "Lễ Phục sinh",
        "baroque": "Baroque",
        "cottagecore": "Phong cách nhà quê",
        "japanesestyle": "Phong cách Nhật Bản",
        "rustic": "Mộc mạc",
        "artnouveau": "Nghệ thuật mới",
        "maximalist": "Tối đa",
        "coffee": "Cà phê",
        "medieval": "Trung cổ",
        "frenchcountry": "Nông thôn Pháp",
        "vaporwave": "Vaporwave",
        "hotpink": "Hồng nóng",
        "gaming": "Chơi game",
        "cyberpunk": "Cyberpunk",
        dating: "Hẹn hò",
        other: "Khác",
        custom: {
            title: 'Phong cách tùy chỉnh',
            btn: 'Bắt đầu tùy chỉnh',
            dialogTitle: 'Phong cách và trang phục của bạn',
            dialogSubTitle: 'Hãy cho chúng tôi biết phong cách, trang phục, tuổi của bạn, nhiếp ảnh gia AI của chúng tôi sẽ cố gắng giúp bạn xuất hiện với nó!',
            upgrade: 'Nâng cấp tính năng này',
            example: 'một bộ vest cổ điển không cà vạt, 26 tuổi tùy thuộc vào những gì bạn muốn...',
        },
        "cyberpunk_style_bathroom": "Phong cách Cyberpunk",
        "eclectic_style_coworking_space": "Phong cách Eclectic",
        "maximalist_style_bedroom": "Phong cách Tối đa",
        "contemporary_style_hotel_bedroom": "Phong cách Đương đại",
        "zen_style_bedroom": "Phong cách Zen",
        "cyberpunk_style_outdoor_patio": "Phong cách Cyberpunk",
        "midcentury_modern_style_pool": "Phong cách Hiện đại giữa thế kỷ",
        "easter_style_drop_zone": "Phong cách Lễ Phục sinh",
        "coastal_style_kitchen": "Phong cách Ven biển",
    },
    backgrounds: {
        coffeeShop: "Quán cà phê",
        office: "Văn phòng",
        cityStreet: "Đường phố",
        factory: "Nhà máy",
        garden: "Vườn",
        carShowroom: "Showroom xe hơi",
        bookShelf: "Kệ sách",
        hotel: "Khách sạn",
        goldenGate: "Cổng Vàng",
        snow: "Tuyết",
        realtor: "Môi giới bất động sản",
        brick: "Gạch",
        custom: {
            title: 'Nền tùy chỉnh',
            btn: 'Bắt đầu tùy chỉnh',
            dialogTitle: 'Nền của bạn',
            dialogSubTitle: 'Hãy cho chúng tôi biết nền của bạn, nhiếp ảnh gia AI của chúng tôi sẽ cố gắng giúp bạn xuất hiện với nó!',
            upgrade: 'Nâng cấp tính năng này',
            example: 'một quán cà phê, tùy thuộc vào nơi bạn muốn...',
        },
    },
    removeTitle: 'Xóa ảnh này',
    removeSub: 'Bạn có chắc chắn muốn xóa ảnh này không? Ảnh này sẽ bị xóa vĩnh viễn. Hành động này không thể hoàn tác.',
    renewTitle: 'Làm mới ảnh này',
    renewSub: 'Chúng tôi sẽ xem xét yêu cầu của bạn. Nếu ảnh này có vấn đề, nó sẽ bị xóa vĩnh viễn. Bạn sẽ nhận được 10 tín dụng mới.',
    reasonTitle: 'Hãy cho chúng tôi biết tại sao bạn muốn làm mới nó?',
    reasonEx: 'Nó không chính thức...',
    photographers: {
        sam: {
            name: "Sam, 👋 Xin chào khách hàng",
            title: "Tôi giỏi về Phong cách",
            intro: "Tôi có thể làm gì?",
            intro1: "Bất kỳ Phong cách và Tâm trạng nào trong vài giây",
            intro2: "Ảnh bất động sản, Nghệ thuật và Blog",
            intro3: "Thiết kế Nội thất & Ngoại thất",
            intro4: "Lấp đầy phòng trống bằng Nội thất",
            intro5: "Render thực tế ảo"
        },
        david: {
            name: "David, 👋 Xin chào khách hàng",
            title: "Tôi giỏi về Phác thảo",
            intro: "Tôi có thể làm gì?",
            intro1: "Bất kỳ Phác thảo nào thành ảnh thực tế trong vài giây",
            intro2: "Phác thảo thành bất kỳ thiết kế nào",
            intro3: "Thiết kế nhà",
            intro4: "Thiết kế kiến trúc",
            intro5: "Thiết kế nội thất"
        },
        annie: {
            name: "Annie, 👋 Xin chào khách hàng",
            title: "Tôi giỏi về Cải tạo",
            intro: "Tôi có thể làm gì?",
            intro1: "Giữ nguyên cửa sổ, trần nhà và cửa ra vào.",
            intro2: "Lấp đầy phòng bằng Nội thất",
            intro3: "Thiết kế nhà",
            intro4: "Thiết kế kiến trúc",
            intro5: "Thiết kế nội thất"
        }
    },
    roomsList: {
        "living_room": "Phòng khách",
        "bedroom": "Phòng ngủ",
        "dining_room": "Phòng ăn",
        "bath_room": "Phòng tắm",
        "study_room": "Phòng học",
        "gaming_room": "Phòng chơi game",
        "meeting_room": "Phòng họp",
        "kitchen": "Nhà bếp",
        "toilet": "Nhà vệ sinh",
        "coworking_space": "Không gian làm việc chung",
        "fitness_gym": "Phòng tập thể dục",
        "mudroom": "Phòng để giày dép",
        "walk_in_closet": "Tủ quần áo đi bộ",
        "office": "Văn phòng",
        "hotel_room": "Phòng khách sạn",
        "hotel_lobby": "Sảnh khách sạn",
        "hotel_bathroom": "Phòng tắm khách sạn",
        "exhibition_space": "Không gian triển lãm",
        "house_exterior": "Ngoại thất nhà",
        "attic": "Gác mái",
        "onsen": "Suối nước nóng",
        "drop_zone": "Khu vực để đồ",
        "workshop": "Xưởng làm việc",
        "nail": "Tiệm làm móng",
        "restaurant": "Nhà hàng",
        "coffee_shop": "Quán cà phê",
        "clothing_store": "Cửa hàng quần áo",
        "family_room": "Phòng gia đình",
        "kids_room": "Phòng trẻ em",
        "balcony": "Ban công",
        "wine_cellar": "Hầm rượu",
        "rooftop_terrace": "Sân thượng",
        "sunroom": "Phòng phơi nắng",
        "home_spa": "Spa tại nhà",
        "craft_room": "Phòng thủ công",
        "dressing_room": "Phòng thay đồ",
        "guest_bedroom": "Phòng ngủ khách",
        "home_bar": "Quầy bar tại nhà",
        "library": "Thư viện",
        "art_studio": "Phòng vẽ",
        "yoga_studio": "Phòng tập yoga",
        "photo_studio": "Studio chụp ảnh",
        "multimedia_room": "Phòng đa phương tiện",
        "auditorium": "Hội trường",
        "medical_exam_room": "Phòng khám bệnh",
        "reception_area": "Khu vực lễ tân",
        "music_room": "Phòng nhạc",
        "science_laboratory": "Phòng thí nghiệm khoa học",
        "home_theater": "Rạp chiếu phim tại nhà",
        "wedding_room": "Phòng cưới",
        "laundry_room": "Phòng giặt ủi",
        "outdoor_kitchen": "Bếp ngoài trời",
        "utility_room": "Phòng tiện ích",
        "pet_room": "Phòng thú cưng",
        "home_gym": "Phòng tập thể dục tại nhà",
        "lounge": "Phòng chờ",
        "play_room": "Phòng chơi",
        "reading_nook": "Góc đọc sách",
        "sauna": "Phòng xông hơi",
        "man_cave": "Phòng riêng cho đàn ông",
        "foyer": "Tiền sảnh",
        "green_house": "Nhà kính",
        "she_shed": "Phòng riêng cho phụ nữ",
        "conservatory": "Phòng kính",
        "nursery": "Phòng trẻ sơ sinh",
        "prayer_room": "Phòng cầu nguyện",
        "side_of_house": "Bên hông nhà",
        "front_of_house": "Mặt tiền nhà",
        "back_of_house": "Phía sau nhà",
        "backyard": "Sân sau",
        "patio": "Sân trong",
        "terrace": "Hiên nhà",
        "front_yard": "Sân trước",
        "garden": "Vườn",
        "court_yard": "Sân trong",
        "pool_area": "Khu vực hồ bơi",
        "porch": "Hiên nhà",
        "playground": "Sân chơi"
    },
    giveAway: {
        title: 'Giảm 10% cho tất cả gói',
        subTitle: 'Nhận thông báo về tính năng mới và quà tặng bằng cách sử dụng ứng dụng của chúng tôi 👇',
        enterYourEmail: 'Nhập email của bạn...',
        notifyMe: 'Nhận ngay ↗',
        note: 'Chúng tôi chỉ gửi cập nhật quan trọng. Không spam! Hủy đăng ký bất cứ lúc nào.',
        submitting: 'Đang gửi...',
        emailSent: 'Đã gửi email.',
        emailSentSub: 'Vui lòng kiểm tra email và bắt đầu sử dụng Decorify!',
        emailSentTitle: 'Tuyệt vời! Bạn đã tham gia!',
    },
    feedback: {
        title: 'Gửi phản hồi của bạn!',
        subTitle: 'Vui lòng cho chúng tôi biết cách cải thiện. Bạn sẽ có cơ hội giành được 100 tín dụng!',
        enterYourFeedback: 'Hãy cho chúng tôi biết...',
        notifyMe: 'Gửi ngay ↗',
        note: 'Chúng tôi chỉ gửi các cập nhật quan trọng của ứng dụng. Không spam bạn! Hủy đăng ký bất cứ lúc nào.',
        submitting: 'Đang gửi...',
        sent: 'Chúng tôi đã nhận được phản hồi của bạn ✅',
        sentSub: 'Chúng tôi sẽ xem xét phản hồi của bạn và phản hồi sớm!',
        type: {
            bug: 'Báo cáo lỗi',
            feature: 'Yêu cầu tính năng',
            feedback: 'Phản hồi khác',
            refund: 'Yêu cầu hoàn tiền',
        },
        emptyFeedback: 'Vui lòng nhập ý kiến của bạn!',
        emptyFeedbackType: 'Vui lòng chọn loại phản hồi!',
    }
}

export default vi;
